<template>
  <div>
    <div class="text-center mb-5">
      <h5>Aplicaciones cliente </h5>
      <span class="text-secondary d-block mb-3">Administre las conexiones desde otras aplicaciones.</span>
    </div>

    <DataGrid :configuracion="datagrid_config" :data="apps" @ready="buscar_btns">
      <button class="btn btn-info mr-2" @click="cargar_apps"><i class='fa fa-refresh'></i> Recargar</button>
      <button class="btn btn-primary mr-2" @click="ver_formulario=true"><i class='fa fa-plus-circle'></i> Nuevo</button>
    </DataGrid>

    <Formulario v-if="ver_formulario" :aplicacion_id="aplicacion_id" :cliente_id="cliente_id" :app="app" @close="cerrar_formulario" @update="cargar_apps" />
  </div>
</template>

<script>
import DataGrid from '@/components/DataGridV3';
import Formulario from './Aplicaciones/Formulario';

import apiClientes from '@/apps/bpb_apps_admin/api/clientes.api';

export default {
  components: {
    DataGrid, Formulario
  },
  props: {
    aplicacion_id: {
      type: String,
      required: true
    },
    cliente_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      datagrid_config: {
        name: 'apps',
        cols: {
          otro: 'Opciones',
          nombre: 'Aplicacion',
          token: 'Token',
          estatus: 'Estatus'
        },
        mutators: {
          otro(val, row, vue) {
            return '<button class="btn btn-primary rounded-circle token_edit" data-id="'+(row.id)+'"><i class="fa fa-edit"></i></button>';
          },
          token(value) {
            return value.substr(0, 10) + '...';
          }
        },
        show_search: false,
        selector: false
      },
      apps:[],
      app: {
        nombre: null,
        token: null,
        estatus: 'activo'
      },
      ver_formulario: false
    }
  },
  mounted() {
    this.cargar_apps();
  },
  methods: {
    async cargar_apps() {
      this.apps = (await apiClientes.listar_aplicaciones(this.aplicacion_id, this.cliente_id)).data;
    },
    buscar_btns() {
      document.querySelectorAll('.token_edit').forEach((btn) => {
        btn.removeEventListener('click', this.acciones_btns);
        btn.addEventListener('click', this.acciones_btns);
      })
    },
    acciones_btns(e) {
      let id = e.target.tagName == 'I' ? e.target.parentNode.dataset.id : e.target.dataset.id;
      
      this.$log.info('id',id);
      this.$log.info('apps',this.apps);

      this.app = this.apps.find(obj => obj.id == id);
      this.app = this.$helper.clone(this.app);
      
      this.$log.info('app',this.app);

      if (!this.app)
        return this.$helper.showMessage('Error','No se encontro la aplicación','error','alert');

      let roles_ids = this.app.roles.map(rol => rol.account_rol_id);
      this.app.roles = roles_ids;

      this.ver_formulario = true;
    },
    cerrar_formulario(update) {
      this.ver_formulario = false;
      this.app = {
        nombre: null,
        token: null,
        estatus: 'activo'
      }

      if (update) this.cargar_apps();
    }
  }
}
</script>