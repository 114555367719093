<template>
    <Modal :options="{width: !aplicacion.id ? '50vw' : '70vw', close: true}" @close="cerrar">
      <div class="title h2 text-white">Aplicación</div>

      <div class="body">

        <ul class="nav nav-tabs justify-content-center"  v-if="aplicacion.id" >
          <li class="nav-item">
            <a class="nav-link " :class="(tab_activa === 'tab-app') && 'active'"  @click="tab_activa = 'tab-app'" aria-current="page" href="#">Aplicación</a>
          </li>
          <li class="nav-item">
            <a class="nav-link " :class="(tab_activa === 'tab-modulos') && 'active'" @click="tab_activa = 'tab-modulos'" aria-current="page" href="#">Módulos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link " :class="(tab_activa === 'tab-paquetes') && 'active'" @click="tab_activa = 'tab-paquetes'" aria-current="page" href="#">Paquetes</a>
          </li>
          <li class="nav-item">
            <a class="nav-link " :class="(tab_activa === 'tab-clientes') && 'active'" @click="tab_activa = 'tab-clientes'" aria-current="page" href="#">Clientes</a>
          </li>
        </ul>

        <div class="tab-content" id="nav-tabContent">

          <!-- FORM CREAR APLICACIÓN -->
          <div class="tab-pane fade" :class="(tab_activa === 'tab-app') && 'show active'">

            <div class="text-center mb-5">
              <div v-if="!aplicacion.id">
                <!-- <h4><span class="badge badge-pill badge-primary text-light">2</span></h4>-->
                <h5>Crear aplicación</h5>
              </div>
              <h5 v-else>Editar aplicación</h5>
              <small class="text-secondary">Ingresa los datos de la aplicación.</small>
            </div>

            <div class="row form-group">
              <label for="nombre" class="col-form-label col-sm-2">Nombre</label>
              <div class="col-sm-10"><input v-model="aplicacion.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
            </div>
            <div class="row form-group">
              <label for="descripcion" class="col-form-label col-sm-2">Descripción</label>
              <div class="col-sm-10"><input v-model="aplicacion.descripcion" type="text" name="descripcion" id="descripcion" class="form-control"></div>
            </div>
            <div class="row form-group" v-if="!aplicacion.id">
              <label for="descripcion" class="col-form-label col-sm-2">Estatus</label>
              <div class="col-sm-10">
                <select v-model="aplicacion.estatus" name="estatus" id="estatus" class="form-control">
                  <option value="activo">Activo</option>
                  <option value="inactivo">Inactivo</option>
                </select>
              </div>
            </div>

          </div>
          <div class="tab-pane fade" :class="(tab_activa === 'tab-modulos') && 'show active'">
            <Modulos v-if="aplicacion.id" :aplicacion_id="aplicacion.id"></Modulos>
          </div>
          <div class="tab-pane fade" :class="(tab_activa === 'tab-paquetes') && 'show active'">
            <Paquetes v-if="aplicacion.id" :aplicacion_id="aplicacion.id"></Paquetes>
          </div>
          <div class="tab-pane fade" :class="(tab_activa === 'tab-clientes') && 'show active'">
            <Clientes v-if="aplicacion.id" :aplicacion_id="aplicacion.id"></Clientes>
          </div>
        </div>





      </div>
      <div class="footer">
        <div class="row justify-content-end" v-if="tab_activa === 'tab-app'">
          <div class="col-auto" v-if="!aplicacion.id"><button type="button" class="btn btn-primary" @click="guardar" id="btn-crear" name="btn-crear"><i class='fa fa-save mr-sm-1'></i>Guardar</button></div>
          <div class="col-auto" v-else> <button type="button" class="btn btn-primary" id="btn-editar" name="btn-editar" @click="editar"><i class='fa fa-save mr-sm-1'></i>Guardar cambios</button></div>
        </div>
      </div>


  </Modal>
</template>

<script>

import Modal from '@/components/Modal'
import api from '@/apps/bpb_apps_admin/api/aplicaciones.api'
import Modulos from "@/apps/bpb_apps_admin/components/Modulos.vue";
import Paquetes from "@/apps/bpb_apps_admin/components/Paquetes.vue";
import Clientes from "@/apps/bpb_apps_admin/components/clientes/Clientes.vue";

export default {
  name: "AplicacionFormulario",
  components: {Clientes, Paquetes, Modal, Modulos},
  data(){
    return {
      busqueda_activa: false,
      errores_response:{},
      tab_activa: 'tab-app',
      aplicacion: {
        id: null,
        nombre: null,
        descripcion: null,
        estatus: 'activo'
      }
    }
  },
  props: {
    aplicacion_prop: {
      type: Object,
      default () {
        return {
          id: null,
          nombre: null,
          descripcion: null,
          estatus: 'activo'
        }
      }
    }
  },
  computed: {
  },
  mounted() {
    this.aplicacion = this.aplicacion_prop
    if (!this.aplicacion.id){
      this.aplicacion.estatus = 'activo'
    }
  },
  methods: {

    cerrar(){
      this.$emit('close');
    }
  ,async guardar(){
       let payload = {...this.aplicacion}
      await api.crear(payload).then(response => {
        this.$helper.showMessage('Crear aplicación','Aplicación creada exitosamente.', 'success', 'alert')
        this.aplicacion = response.data
        this.tab_activa = 'tab-modulos'
        this.$emit('update')
      })
    }
    ,async editar(){
      await api.editar(this.aplicacion.id, this.aplicacion).then(() => {
        this.$helper.showMessage('Editar aplicación','Aplicación actualizada exitosamente.', 'success', 'alert')
        this.$emit('update')
      })
    }
  }
}
</script>

<style scoped lang="scss">
.tab-pane{
  padding-top: 25px;
}
</style>
