<template>

  <div>

    <div class="text-center mb-5">
      <h5>Clientes</h5>
      <small class="text-secondary">Administre los clientes de su aplicación aquí.</small>
    </div>

    <div class="row mb-3">
      <div class="col-sm-auto">
        <button class="btn btn-sm btn-info" @click="cargar_clientes()"> <i class='fa fa-refresh'></i> Recargar</button>
      </div>
      <div class="col-sm-auto" v-if="$auth.can('bpb_apps_admin','clientes_crear')">
        <button  class="btn btn-sm btn-primary" @click="crear"><i class='fa fa-plus-circle'></i> Nuevo</button>
      </div>
    </div>

    <table class="table table-sm table-striped">
      <thead>
      <tr class="bg-dark">
        <th scope="col"></th>
        <th scope="col">Nombre</th>
        <th scope="col">Razón social</th>
        <th scope="col">RFC</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="!clientes.length"><td colspan="4" class="text-secondary text-center"> No se encontraron clientes para mostrar.</td></tr>
      <tr v-for="item_cliente in clientes">
        <td class="text-center">
          <button type="button" class="btn btn-sm btn-primary rounded-circle fa fa-edit" @click="editar(item_cliente)">
          </button>
          <button type="button" class="btn btn-sm btn-dark rounded-circle  ml-2" @click="pre_eliminar(item_cliente)"><i class="fa fa-trash"></i></button>
        </td>
        <td>{{item_cliente.nombre}}</td>
        <td>{{item_cliente.razon_social}}</td>
        <td>{{item_cliente.rfc}}</td>
      </tr>
      </tbody>
    </table>


    <Modal v-if="fomrmularioCliente" :options="{width: '60vw',type:'primary'}" @close="cerrar_modal">
      <div class="h2 title text-white"> {{!cliente.id ? 'Crear' : 'Editar'}} Cliente</div>

      <div class="body">
        <div class="row form-group">
          <label for="nombre_cliente" class="col-form-label col-sm-2">Nombre</label>
          <div class="col-sm-10"><input v-model="cliente.nombre" type="text" placeholder="Ingresa el nombre" name="nombre_cliente" id="nombre_cliente" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="razon_social_cliente" class="col-form-label col-sm-2">Razón social</label>
          <div class="col-sm-10"><input v-model="cliente.razon_social" type="text" placeholder="Ingresa razón social" name="razon_social_cliente" id="razon_social_cliente" class="form-control"></div>
        </div>

        <div class="row form-group">
          <label for="rfc_cliente" class="col-form-label col-sm-2">RFC</label>
          <div class="col-sm-10"><input v-model="cliente.rfc" type="text" placeholder="Ingresa el RFC"  name="rfc_cliente" id="rfc_cliente" class="form-control" maxlength="13" ></div>
        </div>


        <hr class="my-4">
        <div v-if="cliente.id">
          <ul class="nav nav-pills nav-fill text-center mb-1" style="border-bottom: solid 1px #e0e0e0">
            <li class="nav-item">
              <a class="nav-link rounded-0" :class="(tab_activa === 'tab-roles') && 'active'" aria-current="page" href="#" @click="tab_activa = 'tab-roles'"><i class="fa fa-user-group mr-sm-2"></i>Roles </a>
            </li>
            <li class="nav-item">
              <a class="nav-link rounded-0" :class="(tab_activa === 'tab-usuarios') && 'active'" aria-current="page" href="#" @click="tab_activa = 'tab-usuarios'"><i class="fa fa-user-cog mr-sm-2"></i> Usuarios </a>
            </li>
            <li class="nav-item">
              <a class="nav-link rounded-0" :class="(tab_activa === 'tab-aplicaciones') && 'active'" aria-current="page" href="#" @click="tab_activa = 'tab-aplicaciones'"><i class="fa-solid fa-mobile-screen-button"></i> Aplicaciones </a>
            </li>
            <li class="nav-item">
              <a class="nav-link rounded-0" :class="(tab_activa === 'tab-configuracion-bd') && 'active'" aria-current="page" href="#" @click="tab_activa = 'tab-configuracion-bd'"><i class="fa fa-cogs mr-sm-2"></i> Configuración Base de Datos </a>
            </li>
          </ul>

          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade" :class="(tab_activa === 'tab-roles') && 'show active'" v-if="tab_activa === 'tab-roles'">
              <h5>Roles del cliente</h5>
              <span class="text-secondary d-block mb-3">Administre los roles del cliente aquí.</span>

              <Roles :cliente_id="cliente.id" :aplicacion_id="aplicacion_id"></Roles>
            </div>

            <div class="tab-pane fade" :class="(tab_activa === 'tab-usuarios') && 'show active'" v-if="tab_activa === 'tab-usuarios'">
              <Usuarios :aplicacion_id="aplicacion_id" :cliente_id="cliente.id"></Usuarios>
            </div>

            <div class="tab-pane fade" :class="(tab_activa === 'tab-aplicaciones') && 'show active'" v-if="tab_activa === 'tab-aplicaciones'">
              <Aplicaciones :aplicacion_id="aplicacion_id" :cliente_id="cliente.id" />
            </div>

            <div class="tab-pane fade" :class="(tab_activa === 'tab-configuracion-bd') && 'show active'" v-if="tab_activa === 'tab-configuracion-bd'">
              <ConfiguracionBD :aplicacion_id="aplicacion_id" :cliente_id="cliente.id" ref="configuracionBD" @configuracion_guardada="guardar_aplicacion"></ConfiguracionBD>
            </div>
          </div>
        </div>

      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="guardar"><i class='fa fa-save mr-sm-1'></i>Guardar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cerrar_modal"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>


    <Modal v-if="modalEliminar" :options="{width: '30vw',type:'error'}" @close="cerrar_modal">
      <div class="title">Eliminar Cliente</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás el Cliente: <b>{{ cliente.nombre }}</b> <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="eliminar_cliente"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cerrar_modal"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

  </div>

</template>

<script>

import Modal from '@/components/Modal.vue'
import api from '@/apps/bpb_apps_admin/api/clientes.api'
import Roles from "@/apps/bpb_apps_admin/components/Roles.vue";
import Usuarios from "@/apps/bpb_apps_admin/components/clientes/Usuarios.vue";
import ConfiguracionBD from "@/apps/bpb_apps_admin/components/clientes/ConfiguracionBD.vue";
import Aplicaciones from "@/apps/bpb_apps_admin/components/clientes/Aplicaciones.vue";

export default {
  name: "Clientes",
  components: {ConfiguracionBD, Usuarios, Roles, Modal, Aplicaciones},
  data(){
    return {
      fomrmularioCliente: false
      ,modalEliminar: false
      ,clientes: []
      ,cliente: {
            id: null,
            nombre: null,
            razon_social: null,
            rfc: null,
      }
      ,tab_activa: 'tab-roles'
    }
  },
  props: {
    aplicacion_id: null
  },
  computed: {
  },
  mounted() {
    this.cargar_clientes()
  },
  methods: {

    pre_eliminar(item){
      this.cliente  = item
      this.modalEliminar = true
    }
    ,cerrar_modal(){
      this.fomrmularioCliente = false
      this.modalEliminar = false

      this.cliente = {
        id: null,
        nombre: null,
        razon_social: null,
        rfc: null,
      }

    }
    ,async cargar_clientes(){
      let dataSource = await api.index(this.aplicacion_id)
      this.clientes = dataSource.data;
    }

    ,async crear(){
      this.fomrmularioCliente = true
    }

    ,editar(modulo){
      this.cliente = JSON.parse(JSON.stringify(modulo))
      this.fomrmularioCliente = true
    }

    ,guardar(){
      if (this.tab_activa === 'tab-configuracion-bd')
        this.$refs.configuracionBD.guardar();
      else
        this.guardar_aplicacion()
    }

    ,async guardar_aplicacion(){

      let isValid = await this.$validator({
        nombre_cliente: !this.cliente.nombre ? 'required' : 'min:5|max:255',
        razon_social_cliente: !this.cliente.razon_social ? 'required' : 'min:5|max:255',
        rfc_cliente: !this.cliente.rfc ? 'required' : 'min:12|max:13',
      })

      if (isValid) {

        let payload = {...this.cliente}

        if (!this.cliente.id) {
          const  nuevo_cliente =  await api.crear(this.aplicacion_id,payload)
          this.cliente = nuevo_cliente.data
        }else {
          await api.editar(this.aplicacion_id, this.cliente.id, payload).then(() => {
            this.$helper.showMessage('Editar cliente', 'Cliente actualizado exitosamente.', 'success', 'alert')
            this.cerrar_modal()
          })
        }

        await this.cargar_clientes()

      }
    }

    ,async eliminar_cliente(){
      await api.eliminar(this.cliente.aplicacion_id, this.cliente.id).then(() => {
        this.$helper.showMessage('Eliminar cliente','Cliente eliminada exitosamente.', 'success', 'alert')
        this.cerrar_modal()
        this.cargar_clientes()
      })
    }


  }
  ,watch:{
    "cliente.rfc": {
      handler(value){
        if (!!value){
          this.cliente.rfc =   value.toUpperCase()
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tab-pane{
  padding-top: 25px;
}
#lista-permisos{
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
</style>
