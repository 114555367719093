<template>

  <div>

    <Modal :options="{width:  '60vw'}" @close="$emit('close')">
      <div class="title h2 text-whit"> {{!modulo.id ? 'Crear' : 'Editar'}} Módulo</div>

      <div class="body">

        <div class="row form-group">
          <label for="nombre" class="col-form-label col-sm-2">Nombre</label>
          <div class="col-sm-10"><input v-model="modulo.nombre" type="text" name="nombre_modulo" id="nombre_modulo" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="descripcion" class="col-form-label col-sm-2">Descripción</label>
          <div class="col-sm-10"><input v-model="modulo.descripcion" type="text" name="descripcion_modulo" id="descripcion_modulo" class="form-control"></div>
        </div>

        <hr class="my-4">

        <div v-if="modulo.id">
          <ul class="nav nav-pills nav-fill text-center mb-1" style="border-bottom: solid 1px #e0e0e0">
            <li class="nav-item">
              <a class="nav-link rounded-0" :class="(tab_activa === 'tab-permisos') && 'active'" aria-current="page" href="#" @click="tab_activa = 'tab-permisos'"><i class="fa fa-unlock-alt mr-sm-2"></i>Permisos </a>
            </li>
            <li class="nav-item">
              <a class="nav-link rounded-0" :class="(tab_activa === 'tab-roles') && 'active'" aria-current="page" href="#" @click="tab_activa = 'tab-roles'"><i class="fa fa-user-group mr-sm-2"></i> Roles </a>
            </li>
          </ul>

          <div class="tab-content" id="nav-tabContent">

            <div class="tab-pane fade" :class="(tab_activa === 'tab-permisos') && 'show active'" v-if="tab_activa === 'tab-permisos'">
              <h5>Permisos del módulo</h5>
              <span class="text-secondary d-block mb-3">Administre los permisos del módulo aquí.</span>
              <table class="table table-sm table-striped">
                <thead>
                <tr class="bg-info">
                  <th>Permiso</th>
                  <th class="clearfix">
                    Descripción
                  </th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th colspan="3">
                    <div class="form-row align-items-center">
                      <div class="col-12 mb-sm-2">
                        <span class="text-success  d-block">Crear nuevo permiso</span>
                        <small class="text-secondary d-block">Ingresa los datos del nuevo permiso y agregalo presionando el botón de confirmación.</small>
                      </div>

                      <div class="col">
                        <input v-model="nvo_permiso.permiso" placeholder="Ingresa el permiso" type="text" name="nvo_permiso_permiso" id="nvo_permiso_permiso" class="form-control form-control-sm" autocomplete="off">
                      </div>
                      <div class="col">
                        <input v-model="nvo_permiso.descripcion" placeholder="Ingresa la descripción" type="text" name="nvo_permiso_descripcion" id="nvo_permiso_descripcion" class="form-control form-control-sm" autocomplete="off">
                      </div>
                      <div class="col col-auto">
                        <button type="button" class="btn btn-sm btn-primary rounded-circle" @click="agregar_permiso"><i class="fa fa-check"></i></button>
                        <button type="button" v-if="nvo_permiso.permiso || nvo_permiso.descripcion" class="btn btn-link" @click="reset_nvo_permiso">Cancelar</button>
                      </div>
                    </div>
                  </th>
                </tr>

                <tr v-if="!permisos_tmp.length"><td colspan="3" class="text-secondary text-center"> Sin permisos agregados.</td></tr>

                <tr v-for="(item, index) in permisos_tmp" class="row-permiso">

                  <template v-if="index_editar === index ">
                    <th colspan="3">
                      <div class="form-row align-items-center">
                        <div class="col-12 mb-sm-2">
                          <span class="text-primary  d-block">Editar permiso</span>
                          <small class="text-secondary d-block">Editar datos del permiso, confirma tus cambios presionando el botón de confirmación.</small>
                        </div>

                        <div class="col"><input v-model="permiso.permiso" placeholder="Ingresa el permiso" type="text" name="permiso_editar_nombre" id="permiso_editar_nombre" class="form-control form-control-sm" autocomplete="off"></div>
                        <div  class="col"><input v-model="permiso.descripcion" placeholder="Ingresa la descripción" type="text" name="permiso_editar_descripcion" id="permiso_editar_descripcion" class="form-control form-control-sm" autocomplete="off"></div>
                        <div  class="col col-auto">
                          <button type="button" class="btn btn-sm rounded-circle btn-info" @click="pre_editar_permiso"><i class="fa fa-check"></i></button>
                          <button type="button" class="btn btn-link" @click="cancelar_accion_permiso">Cancelar</button>
                        </div>
                      </div>
                    </th>

                  </template>

                  <template v-else>
                    <th>{{item.permiso}}</th>
                    <td>{{item.descripcion}}</td>
                    <td class="text-center">
                      <button type="button" class="btn btn-info btn-sm rounded-circle"
                              :class="index_editar !== null && index_editar !== index && 'text-grey'"
                              :disabled="index_editar !== null && index_editar !== index"
                              @click="seleccionar_editar(item, index)">
                        <i class="fa fa-edit"></i>
                      </button>

                      <button class="btn btn-sm btn-dark rounded-circle ml-2" :disabled="permiso.id" @click="pre_eliminar_permiso(item,index)">
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </template>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="tab-pane fade" :class="(tab_activa === 'tab-roles') && 'show active'" v-if="tab_activa === 'tab-roles'">
              <h5>Roles del módulo</h5>
              <span class="text-secondary d-block mb-3">Administre los roles del módulo aquí.</span>
              <Roles :modulo_id="modulo.id" @update="$emit('update')"></Roles>
            </div>
          </div>
        </div>



      </div>

      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button type="button" class="btn btn-primary" @click="guardar" :disabled="index_eliminar !== null" id="btn-crear" name="btn-crear"><i class='fa fa-save mr-sm-1'></i>{{!modulo.id ? 'Crear' : 'Guardar cambios de'}} módulo</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="pre_cancela_crear_editar"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <Modal v-if="index_eliminar !== null" :options="{width: '30vw',type:'error'}" @close="index_eliminar = null">
      <div class="title">Eliminar permiso</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás el permiso: <b>{{ permiso.permiso }}</b> <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
            <div class="col-auto"><button class="btn btn-primary" @click="eliminar_permiso"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
            <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_eliminar"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <Modal v-if="modalCamiosSinGuardar" :options="{width: '40vw',type:'warning'}" @close="$emit('close')">
      <div class="title">Cancelar {{!modulo.id ? 'crear nuevo módulo' : 'editar módulo'}} </div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
           Si no guardaste tus cambios la información ingresada del módulo y permisos se perderán y no serán guardados. <br><br>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto mb-sm-2"><button class="btn btn-primary" @click="modalCamiosSinGuardar = false"><i class='fa fa-save mr-sm-1'></i>Prefiero guardar mis cambios</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancela_crear_editar"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>
  </div>


</template>

<script>

import Modal from '@/components/Modal'
import utils from '@/helpers/utilidades'
import api from '@/apps/bpb_apps_admin/api/modulos.api'
import permisosApi from "@/apps/bpb_apps_admin/api/permisos.api";
import Vue from "vue";
import Roles from "@/apps/bpb_apps_admin/components/Roles.vue";

export default {
  name: "ModuloFormulario",
  components: {Roles, Modal},
  data(){
    return {
      tab_activa: 'tab-permisos',
      modalCamiosSinGuardar: false,
      cambios_guardados: true,
      cancelar_crear_modulo: false,
      accion: 'crear'
      ,modulos: []
      ,roles: []
      ,permisos_tmp: []
      ,permiso: {
        id: null,
        modulo_id: null,
        permiso: null,
        descripcion: null,

      }
      ,nvo_permiso: {
        id: null,
        modulo_id: null,
        permiso: null,
        descripcion: null,

      }
      ,index_editar:null
      ,index_eliminar:null
      ,modulo_id:null
      ,modulo: {
        id: null,
        aplicacion_id: null,
        nombre: null,
        descripcion: null,
        total_permisos: null,
        permisos: [],
        roles_count: 0
      }
    }
  },
  props: {
    aplicacion_id: null
    ,modulo_prop: {
      id: null,
      aplicacion_id: null,
      nombre: null,
      descripcion: null,
      total_permisos: null,
      permisos: [],
      roles_count: 0
    }


  },
  computed: {
    validaciones(){
      let validaciones  = {
        nombre_modulo: !this.modulo.nombre ? 'required' : 'min:5|max:255',
        descripcion_modulo: !this.modulo.descripcion ? 'required' : 'min:5|max:255',

      };

      if (this.nvo_permiso.permiso || this.nvo_permiso.descripcion) {
        validaciones = {...validaciones, ...this.validaciones_permiso_crear}
      }

      if (this.index_editar !== null)
        validaciones = {...validaciones, ...this.validaciones_permiso_editar}

      return validaciones
    }
    ,validaciones_permiso_crear(){
      return {
        nvo_permiso_permiso: !this.nvo_permiso.permiso ? 'required' : 'min:1|max:255',
        nvo_permiso_descripcion: !this.nvo_permiso.descripcion ? 'required' : 'min:5|max:255'
      }
    }
    ,validaciones_permiso_editar(){
      return {
        permiso_editar_nombre: !this.permiso.permiso ? 'required' : 'min:5|max:255',
        permiso_editar_descripcion: !this.permiso.descripcion ? 'required' : 'min:5|max:255'
      }
    }
  },
  mounted() {
    if (!this.modulo_prop.id)
      this.modulo.aplicacion_id =  this.aplicacion_id
    else{
      this.modulo = JSON.parse(JSON.stringify(this.modulo_prop))
      this.permiso.modulo_id = this.modulo.id
    }
    this.permisos_tmp = this.modulo.permisos

    this.$watch( 'permisos_tmp.length', function (newValue, oldValue) {
        this.cambios_guardados =  false
      })


  },
  methods: {
    pre_cancela_crear_editar(){
      //Valida si no hay cambios o si el fomrulario de editar esta abierto
      if (!this.cambios_guardados || this.index_editar){
        this.modalCamiosSinGuardar = true
      }else {
        this.cancela_crear_editar()
      }
    }
    ,cancela_crear_editar(){
      this.modalCamiosSinGuardar = false
      this.$emit('close')
    }

    ,seleccionar_editar(item, index){
      this.permiso = Object.assign({},item);
      this.index_editar = index
    }

    ,reset_permiso_seleccionado(){
      this.permiso = {
        id: null,
        modulo_id: this.modulo.id,
        permiso: null,
        descripcion: null,
      }
    }
    ,reset_nvo_permiso(){
      this.nvo_permiso = {
        id: null,
        modulo_id: this.modulo.id,
        permiso: null,
        descripcion: null,
      }
    }

    ,cancelar_accion_permiso(){
      this.index_editar = null
      this.reset_permiso_seleccionado()
    },

    async agregar_permiso(){
      let isValid = await this.$validator(this.validaciones_permiso_crear)
      if (isValid) {
        this.permisos_tmp.push(this.nvo_permiso)
        this.reset_nvo_permiso()

      }
    }

    ,async pre_editar_permiso(){
      let isValid = await this.$validator(this.validaciones_permiso_editar)
      if (isValid && this.index_editar !== null) {

        // validar si no se hicieron cambios
        this.cambios_guardados = (this.permisos_tmp[this.index_editar].nombre === this.permiso.nombre &&
            this.permisos_tmp[this.index_editar].descripcion === this.permiso.descripcion);

        this.permisos_tmp[this.index_editar] = this.permiso
        this.index_editar = null
        this.reset_permiso_seleccionado()
      }
    }

    ,pre_eliminar_permiso(item, index){
      this.permiso = this.permisos_tmp[index];
      this.index_eliminar = index
    }

    ,cancelar_eliminar(){
      this.reset_permiso_seleccionado()
      this.index_eliminar = null
    }

    /**
     * Eliminar permiso de listado, si era un permiso ya creado (con id) emitir update para actualizar listado de módulos
     * @returns {Promise<void>}
     */
    ,async eliminar_permiso(){

      const permiso_id = this.permiso.id;
      let eliminado = false;

      if (permiso_id) {
        await permisosApi.eliminar(this.permiso.modulo_id, this.permiso.id).then(() => {
          this.$helper.showMessage('Eliminar permiso', 'Permiso eliminado exitosamente.', 'success', 'alert')
          eliminado = true;
        })
      }

      if ( (permiso_id && eliminado) || !permiso_id) {
        this.permisos_tmp.splice(this.index_eliminar, 1)
        this.index_eliminar = null
        this.reset_permiso_seleccionado()
        eliminado = true; // si !permiso_id confirma que si se guardarón cambios
      }

      if (permiso_id && eliminado)
        this.$emit('update')

    }


    ,async guardar(){

      let isValid = await this.$validator(this.validaciones)

      if (isValid) {

        await this.pre_editar_permiso()


        let payload = {...this.modulo}

        if (!this.modulo.id) {
         const  nuevo_modulo =  await api.crear(this.aplicacion_id,payload)
          this.modulo = nuevo_modulo.data

        }else {
          await api.editar(this.aplicacion_id, this.modulo.id, payload).then(() => {
            this.$helper.showMessage('Editar módulo', 'Módulo actualizado exitosamente.', 'success', 'alert')
          })
        }

        this.cambios_guardados = true

        await this.guardarPermisos()

        this.$emit('update')
      }
    }

    ,async guardarPermisos(){
      try {
        for (let index_permiso in this.permisos_tmp) {
          if (!this.permisos_tmp[index_permiso].id) {
            const nvo_permiso = await permisosApi.crear(this.modulo.id, this.permisos_tmp[index_permiso])
            Vue.set(this.permisos_tmp,index_permiso, nvo_permiso.data)

          } else
            await permisosApi.editar(this.modulo.id,this.permisos_tmp[index_permiso].id, this.permisos_tmp[index_permiso]);
        }
      }catch (e) {

        this.$log.error('Error',e);

        if (e.response &&  e.response.status !== 400)
          this.$helper.showMessage('Agregar/editar permisos','Ocurrio un error al agregar los permisos al módulo, favor de reportarlo al área de IT.', 'error', 'alert')
      }

    }

  }
  ,
  watch: {
    'modulo.nombre': {
      handler(newValue, oldValue) {
        if (!this.modulo.id && !this.modulo.descripcion && !newValue){
          this.cambios_guardados =  true
        } else if(newValue !== oldValue){
          this.cambios_guardados =  (newValue === this.modulo_prop.nombre)
        }
      }
      },
     'modulo.descripcion': {
      handler(newValue, oldValue) {
        if (!this.modulo.id && !this.modulo.nombre && !newValue){
          this.cambios_guardados =  true
        } else if(newValue !== oldValue){
          this.cambios_guardados =  ( newValue === this.modulo_prop.descripcion)
        }
      }
    }
    ,'nvo_permiso.permiso':{
      handler(newValue, oldValue) {
        if(!this.nvo_permiso.descripcion && !newValue){
          this.cambios_guardados = true;
        }else if(newValue !== oldValue){
          this.cambios_guardados = false;
        }
      }
    }
    ,'nvo_permiso.descripcion':{
      handler(newValue, oldValue) {
        if(!this.nvo_permiso.permiso && !newValue){
          this.cambios_guardados = true;
        }else if(newValue !== oldValue){
          this.cambios_guardados = false;
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
.tab-pane{
  padding-top: 25px;
}
</style>
