<template>

  <div>

    <div class="text-center mb-5">
      <h5>Módulos</h5>
      <small class="text-secondary">Agregue, edite o elimine los módulos de su aplicación aquí.</small>
    </div>

    <div class="row mb-3">
      <div class="col-sm-auto">
        <button class="btn btn-sm btn-info" @click="cargar_modulos()"> <i class='fa fa-refresh'></i> Recargar</button>
      </div>
      <div class="col-sm-auto" v-if="$auth.can('bpb_apps_admin','modulos_crear')">
        <button  class="btn btn-sm btn-primary" @click="crear"><i class='fa fa-plus-circle'></i> Nuevo</button>
      </div>
    </div>

    <table class="table table-sm table-striped">
      <thead>
      <tr class="bg-dark">
        <th scope="col"></th>
        <th scope="col">Nombre</th>
        <th scope="col">Descripción</th>
        <th scope="col">Permisos</th>
        <th scope="col">Roles</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="!modulos.length"><td colspan="4" class="text-secondary text-center"> No se encontraron módulos para mostrar.</td></tr>
      <tr v-for="item_modulo in modulos">
        <td class="text-center">
          <button type="button" class="btn btn-sm btn-primary rounded-circle  ml-2" @click="editar(item_modulo)"><i class="fa fa-edit"></i></button>
          <button type="button" class="btn btn-sm btn-dark rounded-circle  ml-2" @click="pre_eliminar(item_modulo)"><i class="fa fa-trash"></i></button>
        </td>
        <td>{{item_modulo.nombre}}</td>
        <td>{{item_modulo.descripcion}}</td>
        <td>{{item_modulo.permisos_count}}</td>
        <td>{{item_modulo.roles_count}}</td>
      </tr>
      </tbody>
    </table>

    <ModuloFormulario v-if="formularioModulo" :aplicacion_id="aplicacion_id" :modulo_prop="modulo" @close="cerrar_modal" @update="cargar_modulos"></ModuloFormulario>

    <Modal v-if="modalEliminar" :options="{width: '30vw',type:'error'}" @close="cerrar_modal">
      <div class="title">Eliminar módulo</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás el módulo: <b>{{ modulo.nombre }}</b> <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="eliminar_modulo"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cerrar_modal"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

  </div>

</template>

<script>

import Modal from '@/components/Modal'
import api from '@/apps/bpb_apps_admin/api/modulos.api'
import ModuloFormulario from "@/apps/bpb_apps_admin/components/ModuloForm.vue";

export default {
  name: "Modulos",
  components: {ModuloFormulario, Modal},
  data(){
    return {
      formularioModulo: false
      ,modalEliminar: false
      ,modulos: []
      ,modulo: {
            id: null,
            nombre: null,
            descripcion: null,
            total_permisos: null,
            permisos: []
      }
    }
  },
  props: {
    aplicacion_id: null
  },
  computed: {
  },
  mounted() {
    this.cargar_modulos()
  },
  methods: {

    pre_eliminar(item){
      this.modulo  = item
      this.modalEliminar = true
    }
    ,cerrar_modal(){
      this.formularioModulo = false
      this.modalEliminar = false

      this.modulo = {
        id: null,
        nombre: null,
        descripcion: null,
        total_permisos: null,
        permisos: []
      }

    }
    ,async cargar_modulos(){
      let dataSource = await api.index(this.aplicacion_id)
      this.modulos = dataSource.data;
    }

    ,async crear(){
      this.formularioModulo = true
    }

    ,editar(modulo){
      this.modulo = JSON.parse(JSON.stringify(modulo))
      this.formularioModulo = true
    }

    ,async eliminar_modulo(){
      await api.eliminar(this.modulo.aplicacion_id, this.modulo.id).then(() => {
        this.$helper.showMessage('Eliminar módulo','Módulo eliminada exitosamente.', 'success', 'alert')
        this.cerrar_modal()
        this.cargar_modulos()
      })
    }


  }
}
</script>

<style scoped lang="scss">
.tab-pane{
  padding-top: 25px;
}
#lista-permisos{
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
</style>
