<template>

  <div>

    <div class="text-center mb-5">
      <h5>Paquetes</h5>
      <small class="text-secondary">Agregue, edite o elimine los paquetes de su aplicación aquí.</small>
    </div>

    <div class="row mb-3" >
      <div class="col-sm-auto">
        <button class="btn btn-sm btn-info" @click="cargar_paquetes()"> <i class='fa fa-refresh'></i> Recargar</button>
      </div>
      <div class="col-sm-auto"  v-if="$auth.can('bpb_apps_admin','paquetes_crear')">
        <button  class="btn btn-sm btn-primary" @click="open_formulario"><i class='fa fa-plus-circle'></i> Nuevo</button>
      </div>
    </div>

    <div class="alert alert-primary text-center" role="alert" v-if="!paquetes.length">
      No se encontraron paquetes agregados.
    </div>

    <div class="container">
      <div class="row" >
        <div class="col-sm-3" v-for="paquete in paquetes">
          <div class="card border-info mb-2">
            <div class="card-header">{{ paquete.nombre.toUpperCase() }}</div>
            <div class="card-body">

              <span class="mb-sm-1 d-block"> <b>Descripción:</b></span>
              <small class="text-secondary">{{paquete.descripcion}}</small>

              <span class="mt-2 mb-sm-1 d-block"> <b>Módulos:</b> ({{paquete.modulos.length}}) </span>
                <ul class="list-group">
                  <li class="list-group-item border-0 p-0" v-for="(modulo,index) in paquete.modulos" v-if="index <= (maximo_modulos_visualizar-1) || paquete.ver_todo">
                    <i class="fa fa-check-square text-success mr-sm-2"></i>
                    <small>{{ modulo.nombre }}</small>
                  </li>
                </ul>

              <div v-if="paquete.modulos.length > maximo_modulos_visualizar" class="mt-sm-2">
                <button type="button" class="btn btn-sm btn-link" @click="open_formulario(paquete)">
                  Ver todos los módulos
                </button>
              </div>


            </div>

            <div class="card-footer">
              <div class="row justify-content-end">
                <button type="button" class="btn btn-sm btn-primary rounded-circle fa fa-edit" @click="open_formulario(paquete)">
                </button>
                <button type="button" class="btn btn-sm btn-danger rounded-circle  ml-2" @click="pre_eliminar(paquete)"><i class="fa fa-trash"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <Modal v-if="formulario" :options="{width: '50vw',type:'primary'}" @close="cerrar_modal">
      <div class="title">Nuevo Paquete</div>

      <div class="body"  v-if="!modulos.length">
        <div class="alert alert-warning text-center" role="alert">
          No se encontraron módulos de la aplicación, primero deber agregarlos en la sección de módulos.
        </div>
      </div>

      <div class="body"  v-else>
        <div class="row form-group">
          <label for="nombre" class="col-form-label col-sm-2">Nombre</label>
          <div class="col-sm-10"><input v-model="paquete.nombre" type="text" name="nombre_paquete" id="nombre_paquete" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="descripcion" class="col-form-label col-sm-2">Descripción</label>
          <div class="col-sm-10"><input v-model="paquete.descripcion" type="text" name="descripcion_paquete" id="descripcion_paquete" class="form-control"></div>
        </div>

        <div class="mb-3 clearfix">
          <h5 class="d-block"> Módulos ({{paquete.modulos.length}})</h5>
          <small class="text-secondary">Seleccione los módulos del paquete.</small>
          <div class="text-danger" v-if="validacion_seleccion_modulos">
            * Debe seleccionar al menos un módulo.
          </div>

          <ul id="lista-modulos-form" class="mt-2">
            <li v-for="modulo in modulos">
              <input class="form-check-input" type="checkbox" :id="'modulo-'+modulo.id" :value="modulo.id" v-model="paquete.modulos">
              {{ modulo.nombre }}
            </li>
          </ul>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="guardar" :disabled="!paquete.id && !modulos.length"><i class='fa fa-save mr-sm-1'></i>Guardar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cerrar_modal"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <Modal v-if="modalEliminar" :options="{width: '30vw',type:'error'}" @close="cerrar_modal">
      <div class="title">Eliminar paquete</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás el paquete: <b>{{ paquete.nombre }}</b> <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="eliminar_paquete"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cerrar_modal"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

  </div>

</template>

<script>

import Modal from '@/components/Modal'
import modulos from '@/apps/bpb_apps_admin/api/modulos.api'
import api from '@/apps/bpb_apps_admin/api/paquetes.api'

export default {
  name: "Paquetes",
  components: {Modal},
  data(){
    return {
      validacion_seleccion_modulos: false,
      formulario: false
      ,modalEliminar: false
      ,modulos: []
      ,paquetes: []
      ,paquete: {
            id: null,
            aplicacion_id: null,
            nombre: null,
            descripcion: null,
            modulos: []
      }
      ,maximo_modulos_visualizar:5
    }
  },
  props: {
    aplicacion_id: null
  },
  computed: {
  },
  mounted() {
    this.paquete.aplicacion_id = this.aplicacion_id
    this.cargar_paquetes()
  },
  methods: {

    pre_eliminar(item){
      this.paquete  = item
      this.modalEliminar = true
    }
    ,cerrar_modal(){
      this.formulario = false
      this.modalEliminar = false

      this.paquete = {
        id: null,
        aplicacion_id: this.aplicacion_id,
        nombre: null,
        descripcion: null,
        modulos: []
      }

    }
    ,async cargar_paquetes(){
      let dataSource = await api.index(this.aplicacion_id)
      this.paquetes = dataSource.data.map( item =>  ({...item, ver_todo: false }));
    }

    ,async open_formulario(paquete = null){
      try {
        let dataSource = await modulos.index(this.aplicacion_id)
        this.modulos = dataSource.data;

        if (paquete.id) {
          this.paquete =  JSON.parse(JSON.stringify(paquete))
          this.paquete.modulos =  this.paquete.modulos.map(item => item.id)
        }

        this.formulario = true
      }catch (e) {
        this.$log.error('Error',e);
        this.$helper.showMessage('Formulario paquete','Ocurrió un error al cargar el formulario, recargue la página e intente de nuevo, si el problema persiste favor de reportarlo al área de IT.', 'error', 'alert', 20000)
      }
    }

    ,async guardar(){

      this.validacion_seleccion_modulos = !this.paquete.modulos.length
      let isValid = await this.$validator({
        nombre_paquete: !this.paquete.nombre ? 'required' : 'min:5|max:255',
        descripcion_paquete: !this.paquete.descripcion ? 'required' : 'min:5|max:255',
      })

      if (isValid && !this.validacion_seleccion_modulos) {

        if (!this.paquete.id) {
          await api.crear(this.aplicacion_id, this.paquete)
          this.cerrar_modal()
          await this.cargar_paquetes()

        } else {
          await api.editar(this.aplicacion_id, this.paquete.id, this.paquete).then(() => {
            this.$helper.showMessage('Editar paquete', 'Módulo actualizado exitosamente.', 'success', 'alert')
            this.cerrar_modal()
            this.cargar_paquetes()
          })
        }
      }
    }
    ,async eliminar_paquete(){
      await api.eliminar(this.paquete.aplicacion_id, this.paquete.id).then(() => {
        this.$helper.showMessage('Eliminar paquete','Paquete eliminado exitosamente.', 'success', 'alert')
        this.cerrar_modal()
        this.cargar_paquetes()
      })
    }


  }
}
</script>

<style scoped lang="scss">
input.checkbox {
  display: inline-block;
}
.tab-pane{
  padding-top: 25px;
}

#lista-modulos-paquete{
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

#lista-modulos-form{
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
}
</style>
